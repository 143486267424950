/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

html, body{
    margin: 0px;
    top: 0px;
    bottom: 0px;
    font-family: 'News Cycle', sans-serif;
    background-image: url("./assets/background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}


.row {
    margin: 0px !important;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
}

.btn
{
    margin: 2px;
}

.modal-content{
    border: none !important;
}
